import React, { useEffect, useState } from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import { renderPreviewComponents } from "../components";
import BigHeader from "../components/bigHeader";
import QuickSelect from "../components/quickSelect";
const contentful = require('contentful')

const PreviewPage = () => {
  const [content, setContent] = useState({});
  const [hotel, setHotel] = useState('');

  const client = contentful.createClient({
    space: 'u5iejfve9fjb',
    accessToken: '0e6BmpefAoRPkH1WLAIlVeKT0n6DXgFY8qmT4_mLxsU',
    host: 'preview.contentful.com'
  })

  useEffect(() => {
    // if (hotel === '') {
      const url = new URL(window.location)
      const pageId = url.searchParams.get("pageId")
      
      client.getEntry(pageId)
      .then((entry) => {
        console.log("entry", entry)
        setContent(entry.fields)
        setHotel(entry.fields.hotel.toLowerCase())
      })
      .catch(console.error)
    // }
  });

  if (hotel === '') {
    return false
  }

  return (
    <Layout theme={hotel}>
      <SEO title="Vorschau" />
      {content &&
        <BigHeader
          image={content.headerImage.fields}
          alt={content.headerImage.fields.description} 
          additionalClass="home"
          title={content.title}
          subTitle={content.subTitle}
        />
      }
      <QuickSelect linkToZimmer={true} theme={hotel} />

      {content && renderPreviewComponents(content.components, hotel)}

    </Layout>
  )
}

export default PreviewPage
